import React from "react";
import InContact from "../InContact/InContact";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

export default function ContFoot() {
  return (
    <div>
      {/* <Navbar /> */}
      <InContact />
      <Footer />
    </div>
  );
}
